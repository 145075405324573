@import 'assets/scss/variables.scss';

.project-item-wrap {
    display: flex;
    background-size: 150%;
    width: 50%;
    height: 65vh;
    transition: .3s all;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: $gray;

    .bg {
        width: 100%;
        background-size: 156%;
        background-repeat: no-repeat;
        transition: .3s all;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .3;
        
    }
    .project-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120%;
        background: rgba(10,13,16, .7);
        transition: .3s all;
        position: absolute;
        bottom: -300px;
        left: -2rem;
        transform:rotate(2deg);
        padding-bottom: 1rem;
        .project-details {
            transform:rotate(-2deg);
            padding: 2rem 4rem;
            .title {}
            .headline {font-size: 18px;}
            .btn {}
        }

    }

    &.alt-bg {
        background-color: $purple
    }

    &:hover {

        .bg {
            background-size: 100%;
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
            opacity: 1;
        }
        .project-info {
            bottom: -1rem;
            left: -2rem;
        }
    }

    @include sm {
        width: 100%;
        height: 50vh;
        .bg {
            background-size: 100%;
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
            opacity: 1;
        }
        .project-info {
            background: transparent;
            text-shadow: 0px 2px 3px rgba(0,0,0,1);
            bottom: -1rem;
            left: -2rem;
            .project-details {
                padding: 0 4rem;
            }
            .btn {
                display: none;
            }
        }
    }

}