@import 'assets/scss/variables.scss';

svg#video-bg {
    width: 100%;
    height: 100%;
    position:absolute;
    z-index: 0;
    
    & mask path {
        fill: rgba(255, 255, 255, 1);
    }
    
    & > rect {
        fill: $black;
        -webkit-mask: url(#mask);
        mask: url(#mask);
    }
}

video {
    position: absolute;
    top: 0%;
    left: 25%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -2;
    overflow: hidden;
    opacity: 1;
    @include sm { left: 0% }
}