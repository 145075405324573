@import 'assets/scss/variables.scss';

.carousel {
    border-radius: 4px;
    overflow: hidden;
    @include sm {
        overflow: visible;
        .carousel-indicators {
            bottom: -50px;
            li { border-color: white; border-radius: 100px; background-color: white !important; }
        }
    }
}
.carousel-control-prev, .carousel-control-next {
    opacity: 0 !important;
    cursor: pointer !important;
    &:hover {
        opacity: 1 !important;
    }
    
    & .prev-icon, & .next-icon {
        display: inline-block;
        background: no-repeat 50% / 100% 100%;
        padding: 1rem 1.25rem;
    }
}

