@import 'assets/scss/variables.scss';

.sidenav {
  height: 100vh;
  width: 100%;
  position: absolute;
  opacity: 0;
  background-color: $black;
  //background: $black url('../../assets/img/triangle.svg') no-repeat right 100%;
  transition: .3s all;
  top: 0;
  left: 0;
  z-index: -1;
  &.show { 
    opacity: 1; 
    z-index: 9999;
  }
  div.wrap {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {
      margin: 0 0 0 0;
      padding: 0;
      text-align: center;
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 3rem;
        letter-spacing: 3px;
        @include sm { font-size: 2rem }
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $purple;
          }
        }
      }
    }

    .logo-nav {
      margin: 2rem 0 auto 0;
    }

    .social-links {
      margin: auto 0 2rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icons { 
        width: 10vw;
        padding: 1rem 0;
        justify-content: space-around;
        a {
          transition: .3s all;
          &:hover {
            svg > path {fill: $white}
          }
          svg {
            transition: .3s all;
            height: 25px;
            width: auto;
          }
        }
        @include sm {width: 30vw}
      }

      .info {
        letter-spacing: 1px;
        color: $gray;
        text-align: center;
        padding: 0 2.5rem;
      }

    }
  }
  .close-menu {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @extend .btn-transparent;
  }

}