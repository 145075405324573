@import 'assets/scss/variables.scss';

.parallax-header-wrap {
    display: block;
    height: 65vh;
    overflow: hidden;
    z-index: -1;
    opacity: .93;
    &:after {
        content: '';
        width: 100%;
        height: 65vh;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        z-index: -2;
    }
    .parallax-header {
        position: fixed;
        top: 0;
        background-position: center;
        background-repeat: no-repeat;
        transform: translate(0px, 0px);
        height: 65vh;
        width: 100%;
     }
     &.no-tint {
         &:after {
             background: none !important;
         }
     }
     @include sm {
         height: 50vh;
     }
}
 
